import React, { FC } from 'react'
import { Router, Switch } from 'react-router-dom'
import { Query } from 'react-apollo'
import { gql } from 'apollo-boost'

import history from '~/misc/history'

import { Auth0Provider } from '~/contexts/auth0'
import { AuthorizedApolloProvider } from '~/contexts/authorizedApollo'

import { Layout } from '~/components/Layout'

import { NavBar } from '~/containers/NavBar'
import { PrivateRoute } from '~/containers/PrivateRoute'

import { Dashboard } from '~/containers/pages/Dashboard'
import { UpdateProject } from '~/containers/pages/UpdateProject'
import { Projects } from '~/containers/pages/Projects'

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState: any) => {
  history.push(appState?.targetUrl || window.location.pathname)
}

export const App: FC = () => {
  return (
    <Auth0Provider
      domain={process.env.AUTH0_DOMAIN || ''}
      client_id={process.env.AUTH0_CLIENT_ID || ''}
      redirect_uri={location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={process.env.AUTH0_API_IDENTIFIER}
    >
      <AuthorizedApolloProvider>
        <Router history={history}>
          <Layout navBar={<NavBar>Project Iwashi</NavBar>}>
            <Switch>
              <PrivateRoute path="/" exact>
                <Dashboard />
              </PrivateRoute>
              <PrivateRoute path="/projects" exact>
                <Projects />
              </PrivateRoute>
              <PrivateRoute path="/project/new" exact>
                <UpdateProject />
              </PrivateRoute>
              <PrivateRoute
                path="/projects/:name/edit"
                render={props => {
                  return (
                    <Query<any>
                      query={gql`
                        query($name: String!) {
                          project(name: $name) {
                            name
                            displayName
                            description
                            tags
                            revenue
                            costs {
                              name
                              amount
                            }
                            members {
                              id
                            }
                          }
                        }
                      `}
                      variables={{
                        name: props.match.params.name
                      }}
                    >
                      {({ data, loading, error }) => {
                        if (error) {
                          return <span>{error}</span>
                        }

                        if (loading || !data) {
                          return null
                        }

                        return (
                          <UpdateProject
                            project={{ ...data.project, __typename: void 0 }}
                          />
                        )
                      }}
                    </Query>
                  )
                }}
              />
            </Switch>
          </Layout>
        </Router>
      </AuthorizedApolloProvider>
    </Auth0Provider>
  )
}
