/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ComponentPropsWithRef, FC, Fragment, useState } from 'react'
import { Link } from 'react-router-dom'

import AppBar from '@material-ui/core/AppBar'
import Avatar from '@material-ui/core/Avatar'
import ButtonBase from '@material-ui/core/ButtonBase'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

interface User {
  displayName: string
  avatarUri?: string
}

interface UserMenuProps {
  user: User
  onLogout?(): void
}

const UserMenu: FC<UserMenuProps> = ({ user, onLogout }) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null)

  const onClick: ComponentPropsWithRef<typeof ButtonBase>['onClick'] = ev => {
    setAnchor(ev.currentTarget)
  }

  const rickRoll = () => {
    window.open('https://youtu.be/dQw4w9WgXcQ')
    onClose()
  }

  const onClose = () => {
    setAnchor(null)
  }

  const logout = () => {
    onClose()
    onLogout?.()
  }

  return (
    <Fragment>
      <ButtonBase aria-controls="user-menu" onClick={onClick}>
        <Avatar alt={user.displayName} src={user.avatarUri}>
          {user.displayName.toUpperCase()[0]}
        </Avatar>
      </ButtonBase>
      <Menu
        id="user-menu"
        anchorEl={anchor}
        keepMounted
        open={!!anchor}
        onClose={onClose}
      >
        <MenuItem disabled onClick={onClose}>
          Profile
        </MenuItem>
        <MenuItem onClick={rickRoll}>Recieve Rewards</MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </Fragment>
  )
}

interface Props {
  operatable?: boolean
  loginUser?: User

  onLogin?(): void
  onLogout?(): void
}

export const NavBar: FC<Props> = ({
  children,
  onLogin,
  onLogout,
  operatable,
  loginUser
}) => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Link
          to="/"
          css={css`
            flex-grow: 1;

            color: inherit;
            text-decoration: none;
          `}
        >
          <Typography variant="h6">{children}</Typography>
        </Link>
        {loginUser ? (
          <UserMenu user={loginUser} onLogout={onLogout} />
        ) : (
          <Button color="inherit" disabled={!operatable} onClick={onLogin}>
            LOGIN
          </Button>
        )}
      </Toolbar>
    </AppBar>
  )
}
