/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FC } from 'react'

export const WholeScreenLoading: FC = () => {
  return (
    <CircularProgress
      css={css`
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      `}
    />
  )
}
