import { gql } from 'apollo-boost'
import React, { FC } from 'react'
import { useMutation } from 'react-apollo'

import { Formik } from 'formik'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import { UpdateProjectInput, Project } from '@iwashi/graphql'

import history from '~/misc/history'

import { GET_PROJECT } from '~/queries/getProject'
import { GET_PROJECTS } from '~/queries/getProjects'

const CREATE_PROJECT = gql`
  mutation($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      project {
        name
      }
    }
  }
`

const initialValues: UpdateProjectInput = {
  name: '',
  displayName: '',
  description: ''
}

interface Props {
  project?: Project
}

export const UpdateProject: FC<Props> = ({ project }) => {
  const [updateProject] = useMutation(CREATE_PROJECT, {
    refetchQueries: [{ query: GET_PROJECTS }, { query: GET_PROJECT }]
  })

  return (
    <Formik<UpdateProjectInput>
      initialValues={project || initialValues}
      validate={values => ({
        ...(!values.name ? { name: 'Required' } : {}),
        ...(!values.displayName ? { displayName: 'Required' } : {})
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true)

        updateProject({
          variables: {
            input: values
          }
        })
          .then(() => {
            history.push('/projects')
          })
          .catch(err => {
            console.error(err)
            setSubmitting(false)
          })
      }}
    >
      {({
        values,
        errors,
        isSubmitting,
        handleBlur,
        handleChange,
        submitForm
      }) => {
        return (
          <div>
            <div>
              {project ? (
                <span>{project.name}</span>
              ) : (
                <TextField
                  required
                  value={values.name}
                  label="Name"
                  name="name"
                  disabled={isSubmitting}
                  error={!!errors.name}
                  helperText={errors.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              )}
            </div>
            <div>
              <TextField
                required
                value={values.displayName}
                label="Display name"
                name="displayName"
                disabled={isSubmitting}
                error={!!errors.displayName}
                helperText={errors.displayName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div>
              <TextField
                value={values.description}
                label="Description"
                name="description"
                disabled={isSubmitting}
                error={!!errors.description}
                helperText={errors.description}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div>
              <Button
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                Update
              </Button>
            </div>
          </div>
        )
      }}
    </Formik>
  )
}
