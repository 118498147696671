import React, { FC, useEffect } from 'react'
import { Route, RouteProps } from 'react-router-dom'

import { useAuth0 } from '~/contexts/auth0'

import { WholeScreenLoading } from '~/components/WholeScreenLoading'
import { Unauthenticated } from '~/components/Unauthenticated'

export const PrivateRoute: FC<RouteProps> = ({
  component: Component,
  children,
  render,
  path,
  ...rest
}) => {
  const auth0 = useAuth0()

  useEffect(() => {
    if (auth0.isLoading || auth0.isAuthenticated) {
      return
    }

    auth0.loginWithRedirect?.({
      redirect_uri: location.origin,
      appState: {
        targetUrl: path
      }
    })
  }, [auth0.isLoading, auth0.isAuthenticated, path])

  return (
    <Route
      path={path}
      render={props => {
        if (auth0.isLoading) {
          return <WholeScreenLoading />
        }

        if (!auth0.isAuthenticated) {
          return <Unauthenticated />
        }

        if (render) {
          return render(props)
        }

        if (children) {
          return children
        }

        // @ts-ignore: Hard to justify type
        return <Component {...props} />
      }}
      {...rest}
    />
  )
}
