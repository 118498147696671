import React, { FC } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import { Link } from 'react-router-dom'
import { gql } from 'apollo-boost'

import { GET_PROJECT } from '~/queries/getProject'
import { GET_PROJECTS } from '~/queries/getProjects'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

import { Project as ProjectCard } from '~/components/Project'

const DELETE_PROJECT = gql`
  mutation($name: String!) {
    deleteProject(name: $name)
  }
`

export const Projects: FC = () => {
  const { loading, data, error } = useQuery<GET_PROJECTS>(GET_PROJECTS)

  const [deleteProject] = useMutation(DELETE_PROJECT, {
    refetchQueries: [{ query: GET_PROJECT }, { query: GET_PROJECTS }]
  })

  if (loading || !data) {
    return <span>Loading...</span>
  }

  if (error) {
    return <span>{error}</span>
  }

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h2">Projects</Typography>
        </CardContent>
        <CardActions>
          <Button color="primary" component={Link} to="/project/new">
            New Project
          </Button>
        </CardActions>
      </Card>
      {data.projects?.map(p => (
        <ProjectCard
          key={p.name}
          style={{ marginTop: 32 }}
          project={p}
          onDelete={p =>
            deleteProject({
              variables: {
                name: p.name
              }
            })
          }
        />
      ))}
    </>
  )
}
