import React, { FC, useEffect, useState } from 'react'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from 'react-apollo'

import { useAuth0 } from '~/contexts/auth0'

const apiHost =
  process.env.API_HOST || `http://${window.location.hostname}:8082`

const createClient = (token?: string) => {
  return new ApolloClient({
    uri: apiHost + '/graphql',
    request: token
      ? operation => {
          operation.setContext({
            headers: {
              authorization: `Bearer ${token}`
            }
          })
        }
      : void 0
  })
}

const initialClient = createClient()

export const AuthorizedApolloProvider: FC = ({ children }) => {
  const auth0 = useAuth0()

  const [client, setClient] = useState(initialClient)

  useEffect(() => {
    if (!auth0.isAuthenticated) {
      return
    }

    auth0.getToken?.().then(token => {
      client.stop()
      setClient(createClient(token))
    })
  }, [auth0.isAuthenticated])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
