import React, { ComponentPropsWithRef, FC, useMemo } from 'react'

import { NavBar as Component } from '~/components/NavBar'

import { useAuth0 } from '~/contexts/auth0'

type OriginalProps = ComponentPropsWithRef<typeof Component>
type Props = Omit<OriginalProps, 'onLogin'>

export const NavBar: FC<Props> = props => {
  const auth0 = useAuth0()

  const user = useMemo<OriginalProps['loginUser']>(() => {
    return (
      auth0.user && {
        displayName: auth0.user.name,
        avatarUri: auth0.user.picture
      }
    )
  }, [auth0.user])

  return (
    <Component
      {...props}
      operatable={!auth0.isLoading}
      loginUser={user}
      onLogin={() => auth0.loginWithRedirect?.()}
      onLogout={() => auth0.logout?.({ returnTo: location.origin })}
    />
  )
}
