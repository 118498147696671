import { gql } from 'apollo-boost'

import { Query } from '@iwashi/graphql'

export const GET_PROJECT = gql`
  query {
    projects(status: PENDING) {
      name
      displayName
      description
      tags
      status
      revenue
      costs {
        amount
        name
      }
      members {
        id
        displayName
        avatar
      }
    }
  }
`

export interface GET_PROJECT {
  project: Query['project']
}
