import createAuth0Client from '@auth0/auth0-spa-js'
import React, {
  createContext,
  FC,
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback
} from 'react'

// Contexts
type UnwrapPromise<P extends Promise<any>> = P extends Promise<infer T>
  ? T
  : never
type Auth0Client = UnwrapPromise<ReturnType<typeof createAuth0Client>>

interface ContextValue {
  isAuthenticated: boolean
  isLoading: boolean
  user?: {
    [key: string]: any
  }
  auth0Client?: Auth0Client

  loginWithRedirect?(ops?: RedirectLoginOptions): Promise<void>
  handleRedirectCallback(): Promise<void>
  logout?(opts?: LogoutOptions): void
  getToken?(opts?: GetTokenSilentlyOptions): Promise<string>
}

const orphanMethod = () => Promise.reject()

export const Auth0Context = createContext<ContextValue>({
  isAuthenticated: false,
  isLoading: false,

  loginWithRedirect: orphanMethod,
  handleRedirectCallback: orphanMethod,
  logout: orphanMethod
})

export default Auth0Context

// Hooks
export const useAuth0 = () => useContext(Auth0Context)

// Provider
const defaultRedirectCallback = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

interface ProviderProps extends Auth0ClientOptions {
  onRedirectCallback?(result: RedirectLoginResult): void
}

export const Auth0Provider: FC<ProviderProps> = ({
  children,
  onRedirectCallback = defaultRedirectCallback,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [auth0Client, setAuth0Client] = useState<Auth0Client>()
  const [user, setUser] = useState<ContextValue['user']>()

  // Initialize Auth0 client.
  // If the user (browser) has logged in to Auth0 and JWT is still valid,
  // obtain the user information and marked the session as logged in.
  useEffect(() => {
    const init = async () => {
      const client = await createAuth0Client(initOptions)

      setAuth0Client(client)

      // When the user redirect-back from a login page,
      // handle its result.
      if (window.location.search.includes('code=')) {
        const { appState } = await client.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const authenticated = await client.isAuthenticated()

      setIsAuthenticated(authenticated)

      if (authenticated) {
        setUser(await client.getUser())
      }

      setIsLoading(false)
    }

    init()
  }, [])

  const handleRedirectCallback = useCallback(async () => {
    if (!auth0Client) {
      return
    }

    setIsLoading(true)

    await auth0Client.handleRedirectCallback()
    setUser(await auth0Client.getUser())

    setIsAuthenticated(true)
    setIsLoading(false)
  }, [auth0Client])

  const loginWithRedirect = useMemo(
    () => auth0Client?.loginWithRedirect.bind(auth0Client),
    [auth0Client]
  )
  const logout = useMemo(() => auth0Client?.logout.bind(auth0Client), [
    auth0Client
  ])

  const getToken = useMemo(
    () => auth0Client?.getTokenSilently.bind(auth0Client),
    [auth0Client]
  )

  const value = useMemo<ContextValue>(
    () => ({
      isAuthenticated,
      isLoading,
      auth0Client,
      user,
      handleRedirectCallback,
      loginWithRedirect,
      logout,
      getToken
    }),
    [
      isAuthenticated,
      isLoading,
      !auth0Client,
      user,
      handleRedirectCallback,
      loginWithRedirect,
      logout,
      getToken
    ]
  )

  return <Auth0Context.Provider value={value}>{children}</Auth0Context.Provider>
}
