/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import Typography from '@material-ui/core/Typography'
import { FC } from 'react'

export const Unauthenticated: FC = () => {
  return (
    <Typography
      variant="h4"
      css={css`
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
      `}
    >
      Please login to continue.
    </Typography>
  )
}
