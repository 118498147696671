/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FC, ReactChild } from 'react'

import Conainer from '@material-ui/core/Container'

interface Props {
  navBar?: ReactChild
}

export const Layout: FC<Props> = ({ children, navBar }) => {
  return (
    <div
      css={css`
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;

        background-color: #f3f3f3;
      `}
    >
      {navBar}
      <Conainer
        css={css`
          flex-grow: 1;
          position: relative;
          padding-top: 32px;

          overflow-y: auto;
        `}
      >
        {children}
      </Conainer>
    </div>
  )
}
