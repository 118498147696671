/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

const PageLink: FC<{ to: string }> = props => (
  <Button variant="outlined" color="primary" component={Link} {...props} />
)

export const Dashboard: FC = () => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h2">Dashboard</Typography>
        <div
          css={css`
            margin-top: 32px;
            margin-left: -16px;
            display: flex;

            &&& > * {
              margin-left: 16px;
            }
          `}
        >
          <PageLink to="/projects">Projects</PageLink>
          <PageLink to="/project/new">New Project</PageLink>
        </div>
      </CardContent>
    </Card>
  )
}
