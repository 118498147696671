import { gql } from 'apollo-boost'

import { Query } from '@iwashi/graphql'

export const GET_PROJECTS = gql`
  query {
    projects {
      name
      displayName
      description
      tags
      status
      revenue
      costs {
        amount
        name
      }
      members {
        displayName
        avatar
      }
    }
  }
`

export interface GET_PROJECTS {
  projects: Query['projects']
}
