/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FC, CSSProperties } from 'react'
import { Link } from 'react-router-dom'

import { Project as IProject, ProjectStatus } from '@iwashi/graphql'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Chip, { ChipProps } from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'

interface Props {
  className?: string
  style?: CSSProperties

  project: IProject

  onDelete?(p: IProject): void
}

export const Project: FC<Props> = ({ project, onDelete, ...rest }) => {
  return (
    <Card {...rest}>
      <CardContent>
        <Typography variant="h6">{project.displayName}</Typography>
        <div>
          <Status status={project.status} />
        </div>
        {project.description && (
          <Typography
            css={css`
              &&& {
                margin-top: 16px;
              }
            `}
            variant="caption"
            component="p"
          >
            {project.description}
          </Typography>
        )}
      </CardContent>
      <CardActions>
        <Button
          component={Link}
          color="primary"
          to={`/projects/${project.name}/edit`}
        >
          Edit
        </Button>
        <Button onClick={() => onDelete?.(project)}>Delete</Button>
      </CardActions>
    </Card>
  )
}

const Status: FC<{
  status: ProjectStatus
}> = ({ status }) => {
  const color: ChipProps['color'] = (() => {
    switch (status) {
      case ProjectStatus.Done:
        return 'secondary'
      case ProjectStatus.InProgress:
        return 'primary'
      case ProjectStatus.Pending:
      case ProjectStatus.Frozen:
        return 'default'
    }
  })()

  const label = (() => {
    switch (status) {
      case ProjectStatus.Pending:
        return '待機'
      case ProjectStatus.Done:
        return '完了'
      case ProjectStatus.InProgress:
        return '進行中'
      case ProjectStatus.Frozen:
        return '凍結'
    }
  })()

  return <Chip size="small" label={label} color={color} />
}
